<template>
  <div>
    <div class="container">
      <div class="wrapper">
        <Header title="未完成的和解書" backto="/menu" />
        <EmptyState v-show="records.length === 0" notes="沒有未完成的和解書" icon="inbox" />
        <div v-for="(item, key) in records" :key="key">
          <ListItem
            :title="item.city + item.area"
            :des="format(parseISO(item.occuredTime), 'yyyy/MM/dd')"
            :target="`/record/${item.id}/preview`"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { parseISO, format } from 'date-fns';
import { record } from '@/mixins';
import Header from '@/components/Header.vue';
import ListItem from '@/components/ListItem.vue';
import EmptyState from '@/components/EmptyState.vue';

export default {
  mixins: [record],
  computed: {
    ...mapState({
      records: (state) => state.records.records,
    }),
  },
  components: {
    Header,
    ListItem,
    EmptyState,
  },
  methods: {
    parseISO,
    format,
  },
  mounted() {
    this.fetchRecords();
  },
};
</script>

<style></style>
